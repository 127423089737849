
import store from "@/store";
import {defineComponent, nextTick, onMounted, PropType, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import MenuItem, {MenuModel} from "./menu-item.vue";

export default defineComponent({
  components: {
    MenuItem,
  },
  props: {
    defaultActive: {
      type: String,
      default: () => {
        return '';
      },
    },
    openList: {
      type: Array as PropType<any[]>,
      default: () => {
        return [];
      },
    },
    menuList: {
      type: Array as PropType<MenuModel[]>,
      default: () => {
        return [];
      },
    },
  },

});
