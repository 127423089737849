
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  toRefs,
  watch,
} from "vue";
import Heder from "@/modules/heder.vue";
import Memu from "@/modules/memu.vue";
import request from "@/utils/request";
import store from "@/store";
import { MenuModel } from "@/modules/menu-item.vue";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  components: {
    Heder,
    Memu,
    // HistoryViewTags,
  },
  setup() {
    const router = useRouter();
    let l: MenuModel[] = [];
    let openList: string[] = [];
    let state = reactive({
      menuList: l,
      openList,
      defautActive:''
    });

    onMounted(() => {
      getMenuList();
    });
    return {
      ...toRefs(state),
      getMenuList,
    };

    function getMenuList() {
      state.menuList=[
        {
          Name:'用户管理',
          Path:'/user',
        },
        {
          Name:'拍卖管理',
          Path:'/paimai',
        },
      ];

      state.defautActive = state.menuList[0].Path;
      router.push(state.menuList[0].Path);
    }
  },
});
