import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_menu, {
      ref: "elMenu",
      "default-openeds": _ctx.openList,
      "active-text-color": "#fff",
      "background-color": "#202020",
      "text-color": "#aaa",
      class: "menu",
      "default-active": _ctx.defaultActive
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (sub) => {
          return (_openBlock(), _createBlock(_component_MenuItem, {
            key: sub.ID,
            data: sub
          }, null, 8, ["data"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["default-openeds", "default-active"])
  ]))
}