
import store from "@/store";
import { PropType } from "vue";
import { defineComponent } from "vue-demi";
import { useRouter } from "vue-router";

export interface MenuModel {
  ID?: string;
  Children?: MenuModel[];
  // Component?: string;
  // IsCache?: string;
  IsFrame?: string;
  MenuType?: string;
  Name: string;

  OrderNum?: number;
  ParentId?: string;
  Path: string;
  Perms?: string;
  // Query?: string;
  Remark?: string;
  // Status?: string;
  Visible?: string;
  Icon?: string;
  IsOpen?: string;
  BelongTo?:string;
}

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<MenuModel>,
    },
    childrenKey: {
      type: String,
    },
  },
   setup(prop) {
        const router = useRouter();

     return {
       handleSelect,
     }
   
    function handleSelect(d: any) {
      const key = d.Path;

      if (ishttp(key)) {
        window.open(key, "_blank");
      } else {
        router.push(key);
      }
    }

     function ishttp(url: any) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
    }
   }
});
