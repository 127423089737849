
import { reactive, toRefs, defineComponent, onMounted, provide, watch } from "vue";
import { TurnOff } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import store from "@/store";
import request from "@/utils/request";
export default defineComponent({
  props: {
    //接收子组件传值
    msg: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    TurnOff,
  },
  setup(_,ctx) {
    let d: Record<string, any> = [];

    const state = reactive({
      dataList: d,
      projectId:'',
    });
    const router = useRouter();
    
    return {
      ...toRefs(state),
      loginOutClick,
      // chooseProject,
      store
    };
    function loginOutClick(e: any) {

      store.cleanLogin();
      router.push("/login");
    }

  },
});
