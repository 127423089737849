import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_menu_item = _resolveComponent("menu-item", true)!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!

  return (_ctx.data?.Children == undefined || _ctx.data?.Children.length == 0)
    ? (_openBlock(), _createBlock(_component_el_menu_item, {
        key: 0,
        index: _ctx.data?.Path,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelect({Name:_ctx.data.Name,Path:_ctx.data.Path})))
      }, {
        default: _withCtx(() => [
          (_ctx.data.Icon != '#')
            ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 0,
                style: {"height":"14px","width":"14px","margin-right":"15px"},
                "icon-name": _ctx.data.Icon
              }, null, 8, ["icon-name"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(' '+_ctx.data?.Name), 1)
        ]),
        _: 1
      }, 8, ["index"]))
    : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        index: _ctx.data.Path
      }, {
        title: _withCtx(() => [
          (_ctx.data.Icon != '#')
            ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 0,
                style: {"height":"14px","width":"14px","margin-right":"15px"},
                "icon-name": _ctx.data.Icon
              }, null, 8, ["icon-name"]))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.data.Name), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.Children, (d) => {
            return (_openBlock(), _createBlock(_component_menu_item, {
              key: d.ID,
              data: d
            }, null, 8, ["data"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["index"]))
}