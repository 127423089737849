import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-577d9dac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { class: "hone-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heder = _resolveComponent("Heder")!
  const _component_Memu = _resolveComponent("Memu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Heder),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_Memu, {
          menuList: _ctx.menuList,
          openList: _ctx.openList,
          "default-active": _ctx.defautActive
        }, null, 8, ["menuList", "openList", "default-active"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, { class: "router-view" })
      ])
    ])
  ]))
}